import http from "./axios/http";

export function dobet(betInfo) {
  return http.post('/sports/bet/dobet?t=' + new Date().getTime(), betInfo)
}

/*베팅내역*/
export function getBetList(search, pageNum, pageSize, orderBy) {
  return http.post('/sports/bet/my_bet_list?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy + '&t=' + new Date().getTime(), search)
}


export function cancelBet(id) {
  return http.get('/sports/bet/cancel?id=' + id);
}

export function deleteBet(paylod) {
  return http.post('/sports/bet/delete_sportsbet',paylod);
}
export function deleteAllBet(id) {
  return http.get('/sports/bet/del_all?t=' + new Date().getTime() );
}

export function doinplaybet(betInfo) {
  return http.post('/sports/inplay/bet/doinplaybet?t=' + new Date().getTime(), betInfo)
}
